import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetOutboundPage, useGetReceiverList } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundMonitorTransfer } from "../../../services/delivery/models/OutboundMonitorTransfer"
import { OutboundMonitorView, outboundMonitorViewFromJson, outboundMonitorViewToJson } from "../../../services/delivery/models/OutboundMonitorView"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { LOS030PcUi } from "./LOS030PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_LOS030

export const LOS030 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? outboundMonitorViewFromJson(JSON.parse(filters)) : null
    }, [])
    const [filters, setFilters] = useState<OutboundMonitorView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OutboundMonitorTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [receiverList, setReceiverList] = useState<{ first: string, second: string }[]>([])

    const getOutboundList = useGetOutboundPage()
    const search = useCallback((filters: OutboundMonitorView) => {
        getOutboundList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(outboundMonitorViewToJson(filters)))
        })
    }, [getOutboundList, setData])

    useSearchOnLoad(search, filters)

    const getReceiverList = useGetReceiverList()
    useEffect(() => {
        getReceiverList(undefined, { silent: true }).then((result) => {
            setReceiverList(result?.receiverList || [])
        })
    }, [getReceiverList, setReceiverList])

    return <LOS030PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        receiverList={receiverList}
    />
}