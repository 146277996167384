import moment from "moment"
import { Project } from "../../../utils/Project"

export type EtlBatchTransfer = Required & Partial<Optional>

type EtlBatchTransferJson = JsonRequired & Partial<JsonOptional>

export const etlBatchTransferFromJson: Project<EtlBatchTransferJson, EtlBatchTransfer> = json => ({
    ...json,
    currentBatchTime: moment(json.currentBatchTime).toDate(),
    latestBatchTime: moment(json.latestBatchTime).toDate(),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'currentBatchTime' | 'latestBatchTime'> {
    currentBatchTime: Date,
    latestBatchTime: Date,

}

interface JsonRequired {
    batchId: number,
}

interface JsonOptional {
    progressId: string,
    customerCode: string,
    dataSource: number,
    dataType: number,
    status: number,
    currentBatchTime: string,
    latestBatchTime: string,
}
