import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { OutboundStatus } from "../enums/OutboundStatus"

export type OutboundMonitorTransfer = Required & Partial<Optional>

type OutboundMonitorTransferJson = JsonRequired & Partial<JsonOptional>

export const outboundMonitorTransferFromJson: Project<OutboundMonitorTransferJson, OutboundMonitorTransfer> = json => ({
    ...json,
    outboundDate: dateFromJson(json.outboundDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'outboundDate'> {
    shippingMode: ShippingMode,
    status: OutboundStatus,
    outboundDate: Date,
}

interface JsonRequired {
    outboundRefNo: string,
    outboundNo: string,
}

interface JsonOptional {
    receiver: string,
    deliveryNoteNo: string,
    shippingMode: number,
    outboundDate: string,
    outboundTime: string,
    m3: number,
    grossWeight: number,
    netWeight: number,
    obId: number,
    dcId: number,
    status: number,
    datasource: number,
    enableUndoReasons: string[],
}