import { Project } from "@rithe/utils";
import { IntlString } from "../../../utils/IntlString";
import { OutboundCreateFactor, outboundCreateFactorFromJson, OutboundCreateFactorJson } from "./OutboundCreateFactor";


export interface OutboundByStorageDetail {
    messages?: IntlString[],
    data?: OutboundCreateFactor
}

interface OutboundByStorageDetailJson {
    messages?: IntlString[],
    data?: OutboundCreateFactorJson
}


export const outboundByStorageDetailFromJson: Project<
    OutboundByStorageDetailJson,
    OutboundByStorageDetail
> = (obj) => ({
    ...obj,
    data: obj.data ? outboundCreateFactorFromJson(obj.data) : undefined,
})
