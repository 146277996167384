import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { default as React, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { Access } from "../../../components/Access/Access"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { UndoCallbackRowAction } from "../../../components/DataGrid/rowActions/UndoCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { PrintGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadExternalOutboundTemplate, useDownloadOutboundResult, useDownloadOutTemplate } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useDownloadDeliveryNotePDF, useDownloadOutboundPDF, useSubmitOutbound, useUndoOutbound, useWithdrawOutbound } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useDeliveryUploadOutbound, useUploadExternalOutbound } from "../../../services/delivery/apis/deliveryUploadApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { OutboundStatus } from "../../../services/delivery/enums/OutboundStatus"
import { OutboundMonitorTransfer } from "../../../services/delivery/models/OutboundMonitorTransfer"
import { OutboundMonitorView } from "../../../services/delivery/models/OutboundMonitorView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { FunctionId } from "../../../utils/FunctionStore"
import { IntlString } from "../../../utils/IntlString"
import { applicationActions } from "../../Application/applicationSlice"
import { DownloadOutboundForm } from "./DownloadOutboundForm"

interface LOS030PcUiProps {
    filters: OutboundMonitorView,
    setFilters: React.Dispatch<React.SetStateAction<OutboundMonitorView>>,
    search: (filters: OutboundMonitorView) => void,
    data: OutboundMonitorTransfer[],
    totalCount: number,
    receiverList: { first: string, second: string }[],
}

export const LOS030PcUi = (props: LOS030PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, receiverList, data, search }: LOS030PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const columns = useMemo(() => [
        { field: 'outboundRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundRefNo' }), width: 250 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 220 },
        { field: 'receiver', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiverCode' }), width: 180 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'outboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.outboundDate' }), width: 180 },
        { field: 'outboundTime', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundTime' }), width: 180 },
        // { field: 'm3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), width: 180 },
        // { field: 'grossWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 180 },
        // { field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.OutboundStatus, title: intl.formatMessage({ id: 'field.status' }), width: 180 },
        { field: 'deliveryNoteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.deliveryNoteNo' }), width: 240 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.outboundNo, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, receiverList }), [filters, setFilters, search, receiverList])
    const refreshProps = useMemo(() => ({ search, filters }), [filters, search])
    const selectionsProps = useMemo(() => ({ selections }), [selections])

    // const submitDisplay
    const viewable = useCallback((tableRow: TableRow) => tableRow.row?.status === OutboundStatus.COMPLETED, [])
    const editable = useCallback((tableRow: TableRow) => tableRow.row?.status === OutboundStatus.PROCESSING, [])
    const undoable = useCallback((tableRow: TableRow) => tableRow.row?.status === OutboundStatus.COMPLETED, [])
    
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OutboundStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        {/* <ToolbarActionProvider Action={PartsViewAction} /> */}
        <ToolbarActionProvider Action={CreateAction} />
        <ToolbarActionProvider Action={CreateSimpleOutboundAction} />
        <ToolbarActionProvider Action={UploadAction} actionProps={refreshProps} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={selectionsProps} />
        <ToolbarActionProvider Action={PrintAction} actionProps={selectionsProps} />
        <RowActionProvider name="view" Action={ViewRowAction} display={viewable} />
        <RowActionProvider name="edit" Action={EditRowAction} display={editable} />
        <RowActionProvider name="submit" Action={SubmitRowAction} actionProps={refreshProps} display={editable} />
        <RowActionProvider name="delete" Action={WithdrawRowAction} actionProps={refreshProps} display={editable} />
        <RowActionProvider name="undo" Action={UndoRowAction} actionProps={refreshProps} display={undoable} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['m3', 'grossWeight', 'netWeight']}
            columnSettings={{
                receiver: { disableUserControl: true },
                shippingMode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: OutboundMonitorView,
    setFilters: React.Dispatch<React.SetStateAction<OutboundMonitorView>>,
    receiverList: { first: string, second: string }[],
    search: (filters: OutboundMonitorView) => void,
}) => {
    const { filters, receiverList, search, setFilters } = props
    const recivers = useMemo(() => receiverList.map(m => [m.first, m.second] as [string, string]), [receiverList])

    const outboundDateGetValue = useCallback((filters: OutboundMonitorView) => {
        return [filters.outboundDateStart ?? null, filters.outboundDateEnd ?? null]
    }, [])

    const outboundDateMapValue = useCallback((filters: OutboundMonitorView, value: any) => {
        return { ...filters ?? {}, outboundDateStart: value[0], outboundDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: OutboundMonitorView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: OutboundMonitorView) => {
        return [
            filters.outboundRefNo,
            filters.deliveryNoteNo,
            filters.outboundNo,
            filters.receiverList,
            filters.shippingModeList,
            filters.outboundTimeStart || filters.outboundTimeEnd,
            filters.outboundDateStart || filters.outboundDateEnd,
        ].filter(f => f !== null && f !== undefined).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="outboundRefNo" label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <StringItem field="deliveryNoteNo" label={intl.formatMessage({ id: 'field.deliveryNoteNo' })} />
        <EntriesItem field="receiverList" label={intl.formatMessage({ id: 'receiverCode' })} entries={recivers} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        {/* <DateRangeItem field="outboundTime" label={intl.formatMessage({ id: 'field.outboundTime' })} getValue={outboundTimeGetValue} mapValue={outboundTimeMapValue} /> */}
        <DateRangeItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })} getValue={outboundDateGetValue} mapValue={outboundDateMapValue} />
    </FilterToolbarItem>
}

const CreateAction = () => {
    return <CreateRedirectToolbarAction access="LOGI.LOS030.CREATE" title={<FormattedMessage id="createOutbound" />} path="/outbound/create" />
}

const CreateSimpleOutboundAction = () => {
    return <CreateRedirectToolbarAction access="LOGI.LOS030.CREATE-SIMPLE" title={<FormattedMessage id="createSimpleOutbound" />} path="/outbound/create-simple" />
}

const UploadAction = ({ search, filters }: { search: (filter: OutboundMonitorView) => void, filters: OutboundMonitorView }) => {
    const uploadApi = useDeliveryUploadOutbound()
    const uploadExternalApi = useUploadExternalOutbound()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const upload = useCallback((file: File) => {
        uploadApi({ file, isConfirm: false }, { serialized: true, silent: true }).then(warningMessages => {
            if (warningMessages.length === 0) {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /lcbm-logistics-api/upload/uploadOutbound' }),
                    messages: [{ code: 'notice.success' }],
                }))
                search(filters)
            } else {
                const functionId = functionStore.register(() => {
                    uploadApi({ file, isConfirm: true }, { serialized: true }).then(() => {
                        search(filters)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: intl.formatMessage({ id: 'confirm' }),
                    messages: warningMessages,
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        })
    }, [dispatch, filters, functionStore, intl, search, uploadApi])

    const uploadMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files === null) {
                return
            } else {
                upload(files[0])
            }
        })
    }, [upload])

    const uploadExternal = useCallback((file: File) => {
        uploadExternalApi({ file, isConfirm: false }, { serialized: true, silent: true }).then(warningMessages => {
            if (warningMessages.length === 0) {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /lcbm-logistics-api/upload/uploadExternalOutbound' }),
                    messages: [{ code: 'notice.success' }],
                }))
                search(filters)
            } else {
                const functionId = functionStore.register(() => {
                    uploadExternalApi({ file, isConfirm: true }, { serialized: true }).then(() => {
                        search(filters)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: intl.formatMessage({ id: 'confirm' }),
                    messages: warningMessages,
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        })
    }, [dispatch, filters, functionStore, intl, search, uploadExternalApi])

    const uploadExternalMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files === null) {
                return
            } else {
                uploadExternal(files[0])
            }
        })
    }, [uploadExternal])
    return <UploadGroupedToolbarAction>
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => uploadMethod(popupUpload)} onClose={onClose} access="LOGI.LOS030.UPLOAD" />
            <GroupedCallbackItem label={<FormattedMessage id="uploadExternalOutbound" />} callback={() => uploadExternalMethod(popupUpload)} onClose={onClose} access="LOGI.LOS030.EXTERNALUPLOAD" />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections }: { selections: string[], }) => {

    const dispatch = useDispatch()

    // open download outbound form
    const [open, setOpen] = useState<boolean>(false)
    const [isInternal, setIsInternal] = useState<boolean>(true)
    const openDownloadInput = useCallback((isInternal: boolean) => {
        setOpen(true)
        setIsInternal(isInternal)
    }, [setOpen, setIsInternal])

    // download blank file
    const downloadApi = useDownloadOutTemplate()
    const downloadExternalApi = useDownloadExternalOutboundTemplate()
    const downloadBlankFile = useCallback(() => {
        downloadApi([])
    }, [downloadApi])
    const downloadBlankExternalFile = useCallback(() => {
        downloadExternalApi([])
    }, [downloadExternalApi])

    // download outbound result
    const downloadBySelectionApi = useDownloadOutboundResult()
    const downloadOutboundResult = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
        } else {
            downloadBySelectionApi({ outboundNoList: selections })
        }
    }, [dispatch, downloadBySelectionApi, selections])

    // layout
    return <>
        <DownloadGroupedToolbarAction>
            {onClose => <>
                <Access access="LOGI.LOS030.DOWNLOAD">
                    <GroupedCallbackItem label={<FormattedMessage id="DownloadOutboundForm" />} callback={() => openDownloadInput(true)} onClose={onClose} />
                    <GroupedCallbackItem label={<FormattedMessage id="downloadBlankOutbound" />} callback={downloadBlankFile} onClose={onClose} />
                    <GroupedCallbackItem label={<FormattedMessage id="downloadOutboundResult" />} callback={downloadOutboundResult} onClose={onClose} />
                </Access>
                <Access access="LOGI.LOS030.EXTERNALDOWNLOAD">
                    <GroupedCallbackItem label={<FormattedMessage id="DownloadExternalOutboundForm" />} callback={() => openDownloadInput(false)} onClose={onClose} />
                    <GroupedCallbackItem label={<FormattedMessage id="downloadBlankExternalOutbound" />} callback={downloadBlankExternalFile} onClose={onClose} />
                    <GroupedCallbackItem label={<FormattedMessage id="downloadExternalOutboundResult" />} callback={downloadOutboundResult} onClose={onClose} />
                </Access>
            </>}
        </DownloadGroupedToolbarAction>
        <DownloadOutboundForm open={open} setOpen={setOpen} isInternal={isInternal} />
    </>
}

const PrintAction = ({ selections }: { selections: string[] }) => {
    const outboundPDFApi = useDownloadOutboundPDF()
    const printDeliveryNoteApi = useDownloadDeliveryNotePDF()
    const dispatch = useDispatch()
    const print = useCallback(() => {
        // do check
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        outboundPDFApi({ outboundNos: selections })
    }, [dispatch, outboundPDFApi, selections])

    const printDeliveryNote = useCallback(() => {
        // do check
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        // printDeliveryNoteApi({ reportId: 'LOR030DeliveryNoteReport', labelId: 'Invoices', param: selections.join(',') })
        printDeliveryNoteApi({ type: 0, outboundNos: selections })
    }, [dispatch, printDeliveryNoteApi, selections])

    const printDeliveryNoteWithPackingList = useCallback(() => {
        // do check
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        // printDeliveryNoteApi({ reportId: 'LOR030DeliveryNotePackingReport', labelId: 'Invoices', param: selections.join(',') })
        printDeliveryNoteApi({ type: 1, outboundNos: selections })

    }, [selections, printDeliveryNoteApi, dispatch])

    return <PrintGroupedToolbarAction access="LOGI.LOS030.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="printWithoutPackingList" />} callback={printDeliveryNote} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="printWithPackingList" />} callback={printDeliveryNoteWithPackingList} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const simpleOutbound = tableRow.row?.datasource !== null && tableRow.row?.datasource === OutboundDatasource.INTERNAL_NO_ORDER
    const path = useCallback((tableRow: TableRow) => simpleOutbound ? `/outbound/view-simple-${tableRow.rowId}` : `/outbound/view-${tableRow.rowId}`, [simpleOutbound])
    return simpleOutbound ? <ViewRedirectRowAction tableRow={tableRow} access="LOGI.LOS030.VIEWSIMPLEDETAIL" path={path} /> :
            <ViewRedirectRowAction tableRow={tableRow} access="LOGI.LOS030.VIEWDETAIL" path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const simpleOutbound = tableRow.row?.datasource !== null && tableRow.row?.datasource === OutboundDatasource.INTERNAL_NO_ORDER
    const path = useCallback((tableRow: TableRow) => simpleOutbound ? `/outbound/edit-simple-${tableRow.rowId}` : `/outbound/edit-${tableRow.rowId}`, [simpleOutbound])
    return simpleOutbound ? <EditRedirectRowAction tableRow={tableRow} access="LOGI.LOS030.EDITSIMPLEDETAIL" path={path} /> : 
                <EditRedirectRowAction tableRow={tableRow} access="LOGI.LOS030.VIEWDETAIL" path={path} />
}

const SubmitRowAction = ({ tableRow, search, filters }:
    DataGridRowActionProps & {
        search: (filters: OutboundMonitorView) => void,
        filters: OutboundMonitorView
    }) => {

    const submitOutbound = useSubmitOutbound()
    const [disabled, setDisabled] = useState<boolean>(false)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            submitOutbound({ outboundNo: tableRow.rowId as string }, { serialized: true, silent: true }).then(warningMessages => {
                if (warningMessages.length === 0) {
                    dispatch(applicationActions.pushSuccess({
                        title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/outbound/submit' }),
                        messages: [{ code: 'notice.success' }],
                    }))
                    search(filters)
                } else {
                    const functionId = functionStore.register(() => {
                        submitOutbound({ outboundNo: tableRow.rowId as string, isConfirm: true }, { serialized: true }).then(messages => {
                            if (messages.filter(m => m.code !== "c0025").length === 0) {
                                dispatch(applicationActions.pushSuccess({
                                    title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/outbound/submit' }),
                                    messages: [{ code: 'c0026' }],
                                }))
                            }
                            search(filters)
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: intl.formatMessage({ id: 'confirm' }),
                        messages: warningMessages,
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId,
                            label: 'CONFIRM',
                        }]
                    }))
                }
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, intl, search, submitOutbound, title])
    return <SubmitCallbackRowAction tableRow={tableRow} access="LOGI.LOS030.VIEWDETAIL" callback={callback} disabled={disabled} />
}

const WithdrawRowAction = ({ tableRow, search, filters }:
    DataGridRowActionProps & {
        search: (filters: OutboundMonitorView) => void,
        filters: OutboundMonitorView
    }) => {

    const withdrawOutbound = useWithdrawOutbound()
    const pushWarning = usePushWarning()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            withdrawOutbound({ outboundNo: tableRow.rowId as string }, { serialized: true }).then(() => {
                search(filters)
            }).finally(() => {
                setDisabled(false)
            })
        })
        pushWarning(title, functionId)
    }, [filters, functionStore, pushWarning, search, title, withdrawOutbound])
    return <WithdrawCallbackRowAction tableRow={tableRow} access="LOGI.LOS030.VIEWDETAIL" callback={callback} disabled={disabled} />
}

const UndoRowAction = ({ tableRow, search, filters }:
    DataGridRowActionProps & {
        search: (filters: OutboundMonitorView) => void,
        filters: OutboundMonitorView
    }) => {

    const undoOutbound = useUndoOutbound()
    const pushWarning = usePushWarning()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'undo' }), [intl])
    const [disabled, setDisabled] = useState<boolean>((tableRow.row?.enableUndoReasons?.length ?? 0) > 0)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            undoOutbound({ outboundNo: tableRow.rowId as string }, { serialized: true }).then(() => {
                search(filters)
            }).finally(() => {
                setDisabled(false)
            })
        })
        pushWarning(title, functionId)
    }, [filters, functionStore, pushWarning, search, title, undoOutbound])

    // display values
    const tips = useMemo(() => { 
        const reasons = tableRow.row?.enableUndoReasons
        if (reasons && reasons.length > 0) {
            return <>
                <span style={{fontWeight:'bold'}}>{title}</span><span style={{marginLeft:'5px', color:'red'}}>(Disabled)</span>
                {reasons.map((r:string) => <li key={r}>{r}</li>)}
            </> 
        } else {
            return title
        }
    }, [tableRow.row?.enableUndoReasons, title])

    return <UndoCallbackRowAction title={tips} tableRow={tableRow} access="LOGI.LOS030.UNDO" callback={callback} disabled={disabled} />
}

const usePushWarning = () => {
    const dispatch = useDispatch()
    return (title: string, functionId: FunctionId, messages?: IntlString[]) => {
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: messages ?? { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }
}


