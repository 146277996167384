import { IconButton, Tooltip } from '@material-ui/core'
import { TableRow } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import React from 'react'
import { Access } from '../../Access/Access'

interface CallbackRowActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    icon: NonNullable<React.ReactNode>,
    callback: (tableRow: TableRow) => void,
    disabled?: boolean,
}

export const CallbackRowAction = (props: CallbackRowActionProps & DataGridRowActionProps) => {
    const { access, power, title, icon, callback, tableRow, disabled } = props

    const action = <Tooltip title={title}>
        <IconButton style={{pointerEvents:'all'}} disabled={disabled} onClick={() => callback(tableRow)}>
            {icon}
        </IconButton>
    </Tooltip>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}