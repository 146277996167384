import { Project } from "@rithe/utils";
import moment from "moment";
import { ShippingMode } from "../../master/enums/ShippingMode";
import { dateFromJson } from "../../utils/deserializer";
import { dateToJson } from "../../utils/serializer";
import { UploadOutboundType } from "../enums/UploadOutboundType";

export interface OutboundCreateFactor {
    buyer: string,
    receiver: string,
    outboundDate: Date,
    outboundTime?: Date,
    outboundNo?: string,
    outboundRefNo: string,
    outboundType: UploadOutboundType,
    etd?: Date,
    eta?: Date,
    isConfirm?: boolean,
    shippingMode: ShippingMode,
    vesselName?: string,
    bookingNo?: string,
    outboundAttached?: OutboundAttachedInfo,
    containers?: ContainerInfo[],
    pids?: OutboundPidLog[],
    datasource: number,
}

export interface OutboundCreateFactorJson {
    buyer: string,
    receiver: string,
    outboundDate: string,
    outboundTime?: string,
    outboundNo?: string,
    outboundRefNo: string,
    outboundType: number,
    etd?: string,
    eta?: string,
    isConfirm?: boolean,
    shippingMode: number,
    vesselName?: string,
    bookingNo?: string,
    outboundAttached?: OutboundAttachedInfoJson,
    containers?: ContainerInfoJson[],
    pids?: OutboundPidLogJson[],
    datasource: number,
}

export const outboundCreateFactorToJson: Project<
    OutboundCreateFactor,
    OutboundCreateFactorJson
> = (obj) => ({
    ...obj,
    outboundDate: dateToJson(obj.outboundDate),
    outboundTime: obj.outboundTime
        ? moment(obj.outboundTime).format(moment.HTML5_FMT.TIME_SECONDS)
        : undefined,
    etd: dateToJson(obj.etd),
    eta: dateToJson(obj.eta),
    outboundAttached: obj.outboundAttached
        ? OutboundAttachedInfoToJson(obj.outboundAttached)
        : undefined,
    containers: obj.containers?.map(ContainerInfoToJson),
    pids: obj.pids?.map(OutboundPidLogToJson),
})

export const outboundCreateFactorFromJson: Project<
    OutboundCreateFactorJson,
    OutboundCreateFactor
> = (json) => ({
    ...json,
    outboundDate: dateFromJson(json.outboundDate),
    outboundTime: json.outboundTime
        ? moment(json.outboundTime, moment.HTML5_FMT.TIME_SECONDS).toDate()
        : undefined,
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    outboundAttached: json.outboundAttached
        ? OutboundAttachedInfoFromJson(json.outboundAttached)
        : undefined,
    containers: json.containers?.map(ContainerInfoFromJson),
    pids: json.pids?.map(OutboundPidLogFromJson),
})

// Outbound attached information
export interface OutboundAttachedInfo {
    externalInvoiceNo?: string,
    incotermsCode?: string,
    incotermsPlace?: string,
    countryOfOrigin?: string,
    paymentTerms?: string,
    buyerName?: string,
    buyerAddress1?: string,
    buyerAddress2?: string,
    buyerAddress3?: string,
    buyerAddress4?: string,
    sellerName?: string,
    sellerAddress1?: string,
    sellerAddress2?: string,
    sellerAddress3?: string,
    sellerAddress4?: string,
    sellerTel?: string,
    sellerFax?: string,
    issueDate?: Date,
}

export interface OutboundAttachedInfoJson {
    externalInvoiceNo?: string,
    incotermsCode?: string,
    incotermsPlace?: string,
    countryOfOrigin?: string,
    paymentTerms?: string,
    buyerName?: string,
    buyerAddress1?: string,
    buyerAddress2?: string,
    buyerAddress3?: string,
    buyerAddress4?: string,
    sellerName?: string,
    sellerAddress1?: string,
    sellerAddress2?: string,
    sellerAddress3?: string,
    sellerAddress4?: string,
    sellerTel?: string,
    sellerFax?: string,
    issueDate?: string,
}

const OutboundAttachedInfoToJson: Project<
    OutboundAttachedInfo,
    OutboundAttachedInfoJson
> = (obj) => ({
    ...obj,
    issueDate: dateToJson(obj.issueDate),
})

const OutboundAttachedInfoFromJson: Project<
    OutboundAttachedInfoJson,
    OutboundAttachedInfo
> = (json) => ({
    ...json,
    issueDate: dateFromJson(json.issueDate),
})

// Container information
export interface ContainerInfo {
    containerNo?: string,
    containerType?: string,
    commodityType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outerPackageList?: OuterPackageInfo[],
}

interface ContainerInfoJson {
    containerNo?: string,
    containerType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outerPackageList?: OuterPackageInfoJson[],
}

const ContainerInfoToJson: Project<ContainerInfo, ContainerInfoJson> = (
    obj
) => ({
    ...obj,
    outerPackageList: obj.outerPackageList?.map(outerPackageInfoToJson),
})

const ContainerInfoFromJson: Project<ContainerInfoJson, ContainerInfo> = (
    json
) => ({
    ...json,
    outerPackageList: json.outerPackageList?.map(outerPackageInfoFromJson),
})

//outer package
export interface OuterPackageInfo {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: Date,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    originalOutboundRefNo?: string,
    innerPackageList: InnerPackageInfo[],
}

interface OuterPackageInfoJson {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    innerPackageList: InnerPackageInfoJson[],
}

export const outerPackageInfoToJson: Project<
    OuterPackageInfo,
    OuterPackageInfoJson
> = (obj) => ({
    ...obj,
    productionDate: dateToJson(obj.productionDate),
    partsList: obj.innerPackageList.map(innerPackageInfoToJson),
})

export const outerPackageInfoFromJson: Project<
    OuterPackageInfoJson,
    OuterPackageInfo
> = (json) => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
    partsList: json.innerPackageList.map(innerPackageInfoFromJson),
})

//inner package
export interface InnerPackageInfo {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    isBlank: boolean,
    partsList: OutboundPartsInfo[],
}

interface InnerPackageInfoJson {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    isBlank: boolean,
    partsList: OutboundPartsInfoJson[],
}

const innerPackageInfoToJson: Project<
    InnerPackageInfo,
    InnerPackageInfoJson
> = (obj) => ({
    ...obj,
    partsList: obj.partsList.map(outboundPartsInfoToJson),
})

const innerPackageInfoFromJson: Project<
    InnerPackageInfoJson,
    InnerPackageInfo
> = (json) => ({
    ...json,
    partsList: json.partsList.map(outboundPartsInfoFromJson),
})

//parts
export interface OutboundPartsInfo {
    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    soDetailId: number,
    contractRouteId: number,
    qty?: number,
    notYetDeliveryQty?: number,
    uomCode?: string,
    netWeight?: number,
    grossWeight?: number,
    externalOrderNo?: string,
    sellingPrice?: number,
    rolledPartsFlag?: number,
}

interface OutboundPartsInfoJson {
    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    soDetailId: number,
    contractRouteId: number,
    qty?: number,
    notYetDeliveryQty?: number,
    uomCode?: string,
    netWeight?: number,
    grossWeight?: number,
    externalOrderNo?: string,
    sellingPrice?: number,
    rolledPartsFlag?: number,
}

const outboundPartsInfoToJson: Project<
    OutboundPartsInfo,
    OutboundPartsInfoJson
> = (obj) => ({
    ...obj,
})

const outboundPartsInfoFromJson: Project<
    OutboundPartsInfoJson,
    OutboundPartsInfo
> = (json) => ({
    ...json,
})

// Container information
export interface OutboundPidLog {
    pid?: string,
    xpid?: string,
    lotNo?: string,
    productCode1?: string,
    productCode2?: string,
    wmsBuCode?: string,
    whsCode?: string,
    outboundJobNo?: string,
    etd?: string,
    dispatchedDate?: string,
    originalQty?: string,
    qty?: string,
    spq?: string,
    weight?: string,
    invoiceNo?: string,
}

interface OutboundPidLogJson {
    pid?: string,
    xpid?: string,
    lotNo?: string,
    productCode1?: string,
    productCode2?: string,
    wmsBuCode?: string,
    whsCode?: string,
    outboundJobNo?: string,
    etd?: string,
    dispatchedDate?: string,
    originalQty?: string,
    qty?: string,
    spq?: string,
    weight?: string,
    invoiceNo?: string,
}

const OutboundPidLogToJson: Project<OutboundPidLog, OutboundPidLogJson> = (
    obj
) => ({
    ...obj,
})

const OutboundPidLogFromJson: Project<OutboundPidLogJson, OutboundPidLog> = (
    json
) => ({
    ...json,
})
